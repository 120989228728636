
export default {
  inject: ['generateId'],
  data() {
    return {
      key1: '',
      key2: '',
    }
  },

  fetch() {
    this.key1 = this.generateId()
    this.key2 = this.generateId()
  },
}
